'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import { uid } from 'uid';
import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import { Label } from '@radix-ui/react-label';
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from '../ui/card';
import { Section } from '../ui/section';
import { getIcon } from '../../icons/getIcon';
import { Button } from '../ui/button';
import type { AboutType, ContactType, IconType } from '@/types';
function Contact(props_1) {
  let _$_1 = $$_1(512, "45.46", null, 47, null, null, null, null, null);
  let {
    data
  }: {
    data: ContactType;
  } = $$_1(2, "45.46", props_1, 48, null, null, 0, null, _$_1);
  return $$_1(16, "45.46", <Section>
      <div data-testid="about_contact_visible" className="flex gap-x-1 pt-1 print:hidden">
        {data.email ? $$_1(16, "45.46", <Button className="size-8" variant="outline" size="icon" asChild>
              <a data-testid="about_contact_email" href={`mailto:${data.email}`}>
                {getIcon('mail', {
            className: 'size-4'
          })}
              </a>
            </Button>, 51, null, null, 2, null, _$_1) : null}
        {data.tel ? $$_1(16, "45.46", <Button className="size-8" variant="outline" size="icon" asChild>
              <a data-testid="about_contact_tel" href={`tel:${data.tel}`}>
                {getIcon('phone', {
            className: 'size-4'
          })}
              </a>
            </Button>, 53, null, null, 3, null, _$_1) : null}
        {data.social.map(social => $$_1(16, "45.46", <Button data-testid={`about_contact_social_${social.name}`} key={uid(32)} className="size-8" variant="outline" size="icon" asChild>
            <a href={social.url}>
              {getIcon((social.name as IconType), {
            className: 'size-4'
          })}
            </a>
          </Button>, 55, null, null, 4, null, _$_1))}
      </div>
      <div data-testid="about_contact_hidden" className="hidden flex-col gap-x-1 print:flex">
        {data.email ? <a data-testid="about_contact_email_hidden" href={`mailto:${data.email}`}>
              <span>{data.email}</span>
            </a> : null}
        {data.tel ? <a data-testid="about_contact_tel_hidden" href={`tel:${data.tel}`}>
              <span>{data.tel}</span>
            </a> : null}
        {data.social ? data.social.map(social => <a key={uid(32)} data-testid={`about_contact_social_${social.name}_hidden`} href={social.url} target="_blank" rel="noreferrer noopener">
              <span>{social.url}</span>
            </a>) : null}
      </div>
    </Section>, 49, null, null, 1, null, _$_1);
}
export function About(props_2) {
  let _$_2 = $$_1(512, "45.64", null, 65, null, null, null, null, null);
  let {
    data
  }: {
    data: AboutType;
  } = $$_1(2, "45.64", props_2, 66, null, null, 0, null, _$_2);
  return $$_1(16, "45.64", <Section>
      {$$_1(16, "45.64", <Card className="border p-3">
        {$$_1(16, "45.64", <CardHeader>
          <div className="block flex flex-col items-center justify-center sm:hidden print:hidden">
            {$$_1(16, "45.64", <Avatar className="size-28">
              {$$_1(16, "45.64", <AvatarImage data-testid="about_avatar_url" alt={data.name} src={data.avatar_url} className="rounded-full" rel="noreferrer noopener" />, 72, null, null, 5, 1, _$_2)}
              {$$_1(16, "45.64", <AvatarFallback>{data.initials}</AvatarFallback>, 73, null, null, 6, null, _$_2)}
            </Avatar>, 71, null, null, 4, null, _$_2)}
            <div data-testid="about_name_small_screen" className="text-2xl font-bold text-primary">
              {data.name}
            </div>
          </div>
          <div data-testid="about_name" className="hidden text-2xl font-bold text-primary sm:block">
            {data.name}
          </div>
        </CardHeader>, 69, null, null, 3, null, _$_2)}
        {$$_1(16, "45.64", <CardContent className="flex-1 space-y-1.5">
          <div className="flex items-center justify-between">
            <div data-testid="about_description" className="max-w-md text-pretty">
              {data.description}
            </div>
            {$$_1(16, "45.64", <Avatar className="hidden size-28 sm:block">
              {$$_1(16, "45.64", <AvatarImage data-testid="about_avatar_url" alt={data.name} src={data.avatar_url} className="rounded-full" />, 80, null, null, 9, 1, _$_2)}
              {$$_1(16, "45.64", <AvatarFallback>{data.initials}</AvatarFallback>, 81, null, null, 10, null, _$_2)}
            </Avatar>, 79, null, null, 8, null, _$_2)}
          </div>
        </CardContent>, 76, null, null, 7, null, _$_2)}
        {$$_1(16, "45.64", <CardFooter className="flex items-center justify-between gap-x-2">
          {$$_1(16, "45.64", <Contact data={data.contact} />, 83, null, null, 12, 1, _$_2)}
          <div className="max-w-md items-center text-pretty text-base">
            <a data-testid="about_location_link" className="hover:point inline-flex" href={data.location_link} target="_blank" rel="noreferrer noopener">
              {getIcon('map', {
              className: 'h-6 w-6 rounded-full inline-flex hover:underline text-green-500',
              href: data.location_link
            })}
              <span data-testid="about_location" className="text-base">
                {data.location}
              </span>
            </a>
          </div>
        </CardFooter>, 82, null, null, 11, null, _$_2)}
      </Card>, 68, null, null, 2, null, _$_2)}
      {$$_1(16, "45.64", <Label data-testid="about_prof_summery_title" className="text-xl font-bold">
        Professional Summary
      </Label>, 87, null, null, 13, null, _$_2)}
      {$$_1(16, "45.64", <Card className="border p-3">
        {$$_1(16, "45.64", <CardHeader>
          {$$_1(16, "45.64", <CardDescription data-testid="about_prof_summery" className="text-pretty">
            {data.professional_summary}
          </CardDescription>, 90, null, null, 16, null, _$_2)}
        </CardHeader>, 89, null, null, 15, null, _$_2)}
      </Card>, 88, null, null, 14, null, _$_2)}
    </Section>, 67, null, null, 1, null, _$_2);
}
registerMetadata_1("45.46", 5, Contact);
registerMetadata_1("45.64", 17, About);