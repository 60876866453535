'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import useColorTheme from '@/hooks/useColorTheme';
import type { DarkColorType, LightColorType } from '@/types';
export function ColorPalette() {
  let _$_1 = $$_1(512, "145.146", null, 147, null, null, null, null, null);
  const {
    setColorTheme
  } = $$_1(4, "145.146", useColorTheme(), 148, null, null, 1, null, _$_1);
  const onToggle = (color: LightColorType | DarkColorType) => {
    setColorTheme(color);
  };
  return <div className="flex flex-wrap gap-1">
      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-rose-600', 'hover:bg-rose-600')} onClick={() => onToggle('rose')}>
          </TooltipTrigger>, 152, null, null, 4, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Rose</p>
          </TooltipContent>, 153, null, null, 5, null, _$_1)}
        </Tooltip>, 151, null, null, 3, null, _$_1)}
      </TooltipProvider>, 150, null, null, 2, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-orange-600', 'hover:bg-orange-600')} onClick={() => onToggle('orange')}>
          </TooltipTrigger>, 157, null, null, 8, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Orange</p>
          </TooltipContent>, 158, null, null, 9, null, _$_1)}
        </Tooltip>, 156, null, null, 7, null, _$_1)}
      </TooltipProvider>, 155, null, null, 6, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-zinc-600', 'hover:bg-zinc-600')} onClick={() => onToggle('zinc')}>
          </TooltipTrigger>, 162, null, null, 12, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Zinc</p>
          </TooltipContent>, 163, null, null, 13, null, _$_1)}
        </Tooltip>, 161, null, null, 11, null, _$_1)}
      </TooltipProvider>, 160, null, null, 10, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-yellow-600', 'hover:bg-yellow-600')} onClick={() => onToggle('yellow')}>
          </TooltipTrigger>, 167, null, null, 16, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Yellow</p>
          </TooltipContent>, 168, null, null, 17, null, _$_1)}
        </Tooltip>, 166, null, null, 15, null, _$_1)}
      </TooltipProvider>, 165, null, null, 14, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-violet-600', 'hover:bg-violet-600')} onClick={() => onToggle('violet')}>
          </TooltipTrigger>, 172, null, null, 20, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Violet</p>
          </TooltipContent>, 173, null, null, 21, null, _$_1)}
        </Tooltip>, 171, null, null, 19, null, _$_1)}
      </TooltipProvider>, 170, null, null, 18, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-green-600', 'hover:bg-green-600')} onClick={() => onToggle('green')}>
          </TooltipTrigger>, 177, null, null, 24, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Green</p>
          </TooltipContent>, 178, null, null, 25, null, _$_1)}
        </Tooltip>, 176, null, null, 23, null, _$_1)}
      </TooltipProvider>, 175, null, null, 22, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-red-600', 'hover:bg-red-600')} onClick={() => onToggle('red')}>
          </TooltipTrigger>, 182, null, null, 28, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Red</p>
          </TooltipContent>, 183, null, null, 29, null, _$_1)}
        </Tooltip>, 181, null, null, 27, null, _$_1)}
      </TooltipProvider>, 180, null, null, 26, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-blue-600', 'hover:bg-blue-600')} onClick={() => onToggle('blue')}>
          </TooltipTrigger>, 187, null, null, 32, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Blue</p>
          </TooltipContent>, 188, null, null, 33, null, _$_1)}
        </Tooltip>, 186, null, null, 31, null, _$_1)}
      </TooltipProvider>, 185, null, null, 30, null, _$_1)}

      {$$_1(16, "145.146", <TooltipProvider>
        {$$_1(16, "145.146", <Tooltip>
          {$$_1(16, "145.146", <TooltipTrigger className={clsx('flex h-8 w-8 rounded-full shadow-2xl print:hidden', 'bg-slate-600', 'hover:bg-slate-600')} onClick={() => onToggle('slate')}>
          </TooltipTrigger>, 192, null, null, 36, null, _$_1)}
          {$$_1(16, "145.146", <TooltipContent>
            <p>Slate</p>
          </TooltipContent>, 193, null, null, 37, null, _$_1)}
        </Tooltip>, 191, null, null, 35, null, _$_1)}
      </TooltipProvider>, 190, null, null, 34, null, _$_1)}
    </div>;
}
registerMetadata_1("145.146", 38, ColorPalette);