'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => {
  let _$_1 = null;
  return $$_1(16, "254.255", <DialogPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-50 bg-black/20 data-[state=open]:animate-in  data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 print:hidden', className)} {...props} />, 257, null, null, 1, 1, _$_1);
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>>(({
  className,
  children,
  ...props
}, ref) => {
  let _$_2 = null;
  return $$_1(16, "254.258", <DialogPortal>
    {$$_1(16, "254.258", <DialogOverlay />, 261, null, null, 2, 1, _$_2)}
    {$$_1(16, "254.258", <DialogPrimitive.Content ref={ref} className={cn('fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] print:hidden sm:rounded-lg', className)} {...props}>
      {children}
      {$$_1(16, "254.258", <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        {$$_1(16, "254.258", <X className="h-4 w-4" />, 264, null, null, 5, 1, _$_2)}
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>, 263, null, null, 4, null, _$_2)}
    </DialogPrimitive.Content>, 262, null, null, 3, null, _$_2)}
  </DialogPortal>, 260, null, null, 1, null, _$_2);
});
DialogContent.displayName = DialogPrimitive.Content.displayName;
function DialogHeader(props_1) {
  let _$_3 = $$_1(512, "254.266", null, 267, null, null, null, null, null);
  let {
    className,
    ...props
  }: React.HTMLAttributes<HTMLDivElement> = $$_1(2, "254.266", props_1, 268, null, null, 0, null, _$_3);
  return <div className={cn('flex flex-col space-y-1.5 text-center sm:text-left', className)} {...props} />;
}
DialogHeader.displayName = 'DialogHeader';
function DialogFooter(props_2) {
  let _$_4 = $$_1(512, "254.270", null, 271, null, null, null, null, null);
  let {
    className,
    ...props
  }: React.HTMLAttributes<HTMLDivElement> = $$_1(2, "254.270", props_2, 272, null, null, 0, null, _$_4);
  return <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />;
}
DialogFooter.displayName = 'DialogFooter';
const DialogTitle = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>(({
  className,
  ...props
}, ref) => {
  let _$_5 = null;
  return $$_1(16, "254.274", <DialogPrimitive.Title ref={ref} className={cn('text-lg font-semibold leading-none tracking-tight', className)} {...props} />, 276, null, null, 1, 1, _$_5);
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>>(({
  className,
  ...props
}, ref) => {
  let _$_6 = null;
  return $$_1(16, "254.277", <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />, 248, null, null, 1, 1, _$_6);
});
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogPortal, DialogOverlay, DialogClose, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription };
registerMetadata_1("254.255", 2, DialogOverlay);
registerMetadata_1("254.258", 6, DialogContent);
registerMetadata_1("254.266", 1, DialogHeader);
registerMetadata_1("254.270", 1, DialogFooter);
registerMetadata_1("254.274", 2, DialogTitle);
registerMetadata_1("254.277", 2, DialogDescription);