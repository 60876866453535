import * as React from 'react';
import type { SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
interface ElectronProps extends SVGProps<SVGSVGElement> {
  className?: string;
}
function Electron({
  className
}: ElectronProps) {
  return <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 128 128" width="1em" height="1em" className={twMerge('h-20 w-20', className)}>
      <circle cx={64} cy={64} r={64} fill="#2F3242" />
      <path fill="#A2ECFB" d="M51.395 39.503a1.8 1.8 0 0 0 .646-3.542l-.646 3.542Zm-22.78 4.072 1.56.9-1.56-.9Zm.324 17.36a1.8 1.8 0 0 0 3.125-1.788l-3.125 1.789Zm8.755 5.81a1.8 1.8 0 0 0-2.665 2.422l2.664-2.421Zm16.055 14.956-.901 1.559.9-1.559Zm43.172 7.814a1.8 1.8 0 1 0-2.03-2.973l2.03 2.973ZM52.04 35.961c-5.57-1.016-10.756-1.064-15.067-.023-4.32 1.043-7.89 3.22-9.918 6.738l3.119 1.798c1.394-2.42 3.97-4.15 7.644-5.037 3.682-.89 8.336-.89 13.576.066l.646-3.542Zm-24.985 6.715c-3.013 5.227-1.778 11.864 1.883 18.26l3.125-1.789c-3.406-5.95-3.955-11.089-1.89-14.673l-3.118-1.798Zm7.973 26.49c4.61 5.074 10.687 9.97 17.819 14.094l1.802-3.117c-6.842-3.956-12.62-8.624-16.956-13.397l-2.665 2.42ZM52.848 83.26c8.496 4.913 17.198 7.984 24.887 9.1 7.624 1.108 14.54.328 19.186-2.845l-2.03-2.973c-3.566 2.435-9.4 3.307-16.639 2.256-7.173-1.041-15.443-3.937-23.602-8.655l-1.802 3.117Zm52.511 1.648a6.292 6.292 0 0 0-6.288-6.295v3.6a2.691 2.691 0 0 1 2.688 2.695h3.6Zm-6.288-6.295a6.291 6.291 0 0 0-6.288 6.295h3.6a2.691 2.691 0 0 1 2.688-2.695v-3.6Zm-6.288 6.295a6.291 6.291 0 0 0 6.288 6.295v-3.6a2.691 2.691 0 0 1-2.688-2.695h-3.6Zm6.288 6.295a6.292 6.292 0 0 0 6.288-6.295h-3.6a2.691 2.691 0 0 1-2.688 2.695v3.6Z" />
      <path fill="#A2ECFB" d="M91.487 65.382a1.8 1.8 0 1 0 2.75 2.324l-2.75-2.324Zm7.83-21.747-1.56.9 1.56-.9Zm-14.81-8.394a1.8 1.8 0 1 0-.047 3.6l.047-3.6Zm-9.566 4.64a1.8 1.8 0 0 0-.756-3.52l.756 3.52Zm-21.157 6.492.9 1.558-.9-1.558ZM25.549 80.905a1.8 1.8 0 0 0 3.562-.522l-3.562.522Zm68.688-13.2c3.647-4.314 6.268-8.778 7.515-13.027 1.249-4.257 1.148-8.43-.876-11.941l-3.119 1.797c1.392 2.415 1.603 5.508.54 9.13-1.065 3.63-3.379 7.658-6.81 11.718l2.75 2.324Zm6.639-24.968c-2.964-5.143-9.168-7.402-16.369-7.496l-.047 3.6c6.673.087 11.266 2.17 13.297 5.693l3.119-1.797Zm-26.69-6.377c-6.75 1.449-14.098 4.288-21.303 8.455l1.802 3.116c6.912-3.997 13.91-6.69 20.256-8.05l-.756-3.52Zm-21.303 8.455c-8.746 5.057-15.916 11.28-20.734 17.578-4.772 6.237-7.434 12.819-6.6 18.512l3.562-.522c-.627-4.28 1.364-9.877 5.897-15.803 4.488-5.866 11.276-11.791 19.677-16.649l-1.802-3.116Z" />
      <path fill="#A2ECFB" d="M49.083 87.23a1.8 1.8 0 1 0-3.39 1.212l3.39-1.212Zm29.48 9.196a1.8 1.8 0 0 0-3.086-1.853l3.087 1.853ZM79.57 85.18a1.8 1.8 0 1 0 3.436 1.073L79.57 85.18Zm4.813-21.113h-1.8 1.8ZM69.156 22.53a1.8 1.8 0 1 0-1.463 3.29l1.463-3.29ZM45.693 88.442c1.906 5.332 4.457 9.849 7.51 13.063 3.06 3.219 6.725 5.226 10.78 5.226v-3.6c-2.785 0-5.566-1.366-8.17-4.106-2.609-2.746-4.936-6.777-6.73-11.796l-3.39 1.213Zm18.29 18.289c5.886 0 10.91-4.189 14.58-10.305l-3.086-1.853c-3.399 5.662-7.465 8.558-11.494 8.558v3.6Zm19.023-20.478c2.02-6.467 3.177-14.07 3.177-22.186h-3.6c0 7.787-1.11 15.025-3.013 21.113l3.436 1.073Zm3.177-22.186c0-9.953-1.74-19.145-4.687-26.417-2.921-7.206-7.152-12.813-12.34-15.12l-1.463 3.29c3.943 1.753 7.692 6.336 10.467 13.183 2.749 6.78 4.423 15.504 4.423 25.064h3.6Z" />
      <path fillRule="evenodd" stroke="#A2ECFB" strokeLinecap="round" strokeWidth={3.6} d="M103.559 84.908a4.491 4.491 0 0 0-4.488-4.495 4.491 4.491 0 0 0-4.488 4.495 4.491 4.491 0 0 0 4.488 4.495 4.491 4.491 0 0 0 4.488-4.495v0ZM28.814 89.403a4.492 4.492 0 0 0 4.488-4.495 4.492 4.492 0 0 0-4.488-4.495 4.491 4.491 0 0 0-4.488 4.495 4.491 4.491 0 0 0 4.488 4.495v0Z" clipRule="evenodd" />
      <ellipse cx={63.983} cy={23.204} stroke="#A2ECFB" strokeLinecap="round" strokeWidth={3.6} rx={4.488} ry={4.495} />
      <path fill="#A2ECFB" fillRule="evenodd" d="M64.85 68.086a4.112 4.112 0 0 1-1.733-8.037 4.105 4.105 0 0 1 4.878 3.15 4.111 4.111 0 0 1-3.145 4.887Z" clipRule="evenodd" />
    </svg>;
}
export { Electron };