'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import { Label } from '@radix-ui/react-label';
import humanizeString from 'humanize-string';
import { uid } from 'uid';
import { getIcon } from '../../icons/getIcon';
import { Card } from '../ui/card';
import { Section } from '../ui/section';
import type { IconType, SkillsType } from '@/types';
export function Skills(props_1) {
  let _$_1 = $$_1(512, "115.116", null, 117, null, null, null, null, null);
  let {
    data
  }: {
    data: SkillsType;
  } = $$_1(2, "115.116", props_1, 118, null, null, 0, null, _$_1);
  return $$_1(16, "115.116", <Section>
      {$$_1(16, "115.116", <Label data-testid="skills_title" className="text-xl font-bold">
        Skills
      </Label>, 120, null, null, 2, null, _$_1)}
      {$$_1(16, "115.116", <Card className="border p-3">
        <ul data-testid="skills_list" className="space-y-4 text-left">
          {Object.entries(data).map(([skillCategory, skills]) => <li key={skillCategory} className="flex flex-wrap items-start items-baseline">
              {getIcon('circle-dot', {
            className: 'h-4 w-4 text-green-500 mt-2'
          })}
              <p className="m-0.5 ml-2 text-lg font-semibold text-gray-900 dark:text-gray-100">
                {humanizeString(skillCategory)}
                :
              </p>
              {skills.map(skill => <span key={uid(32)}>
                  {getIcon((skill as IconType), {
              className: 'mx-0.5 size-4'
            })}
                </span>)}
            </li>)}
        </ul>
      </Card>, 121, null, null, 3, null, _$_1)}
    </Section>, 119, null, null, 1, null, _$_1);
}
registerMetadata_1("115.116", 4, Skills);