'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import { Label } from '@radix-ui/react-label';
import { uid } from 'uid';
import { getIcon } from '../../icons/getIcon';
import { Card } from '../ui/card';
import { Section } from '../ui/section';
import type { KeySkillsType } from '@/types';
export function KeySkills(props_1) {
  let _$_1 = $$_1(512, "91.92", null, 93, null, null, null, null, null);
  let {
    data
  }: {
    data: KeySkillsType;
  } = $$_1(2, "91.92", props_1, 94, null, null, 0, null, _$_1);
  return $$_1(16, "91.92", <Section>
      {$$_1(16, "91.92", <Label data-testid="key_skills_title" className="text-xl font-bold">Key Skills</Label>, 96, null, null, 2, null, _$_1)}
      {$$_1(16, "91.92", <Card className="border p-3">
        <ul data-testid="key_skills_list" className="space-y-4 text-left">
          {data.map(kSkills => <li key={uid(32)} className="flex flex-wrap items-start items-baseline">
              {getIcon('badge-check', {
            className: 'mr-1 h-4 w-4 text-green-500'
          })}
              <div className="mx-0.5 text-lg font-semibold text-gray-900 dark:text-gray-100">
                {kSkills}
              </div>
            </li>)}
        </ul>
      </Card>, 97, null, null, 3, null, _$_1)}
    </Section>, 95, null, null, 1, null, _$_1);
}
registerMetadata_1("91.92", 4, KeySkills);