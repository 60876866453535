'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import { Label } from '@radix-ui/react-label';
import { uid } from 'uid';
import { Section } from '../ui/section';
import { getIcon } from '../../icons/getIcon';
import type { EducationsType } from '@/types';
export function Educations(props_1) {
  let _$_1 = $$_1(512, "101.102", null, 103, null, null, null, null, null);
  let {
    data
  }: {
    data: EducationsType;
  } = $$_1(2, "101.102", props_1, 104, null, null, 0, null, _$_1);
  return $$_1(16, "101.102", <Section>
      {$$_1(16, "101.102", <Label data-testid="education_title" className="text-xl font-bold">
        Education
      </Label>, 106, null, null, 2, null, _$_1)}
      <div className="relative border-l border-gray-200 dark:border-gray-700">
        <div className="space-y-8">
          {data.map((education, index) => <div className="relative pl-6" key={uid(32)}>
              {getIcon('dot', {
            className: 'absolute -left-[12px] top-0',
            strokeWidth: 8
          })}
              <div className="flex flex-wrap items-center justify-between text-base">
                <div data-testid={`education_school_index_${index}`} className="font-semibold leading-none">
                  {education.school}
                </div>
                <div data-testid={`education_start_end_index_${index}`} className="tabular-nums text-gray-500">
                  {education.start}
                  {' '}
                  -
                  {' '}
                  {education.end}
                </div>
              </div>
              <div data-testid={`education_degree_index_${index}`}>
                {education.degree}
              </div>
              <div data-testid={`education_aggregate_index_${index}`} className="text-sm font-semibold">
                Aggregate:
                {' '}
                {education?.aggregate ?? education?.cgpa}
              </div>
            </div>)}
        </div>
      </div>
    </Section>, 105, null, null, 1, null, _$_1);
}
registerMetadata_1("101.102", 3, Educations);