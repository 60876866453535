import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import { useEffect, useState } from 'react';
function useHasMounted() {
  let _$_1 = null;
  const [hasMounted, setHasMounted] = $$_1(4, "249.250", useState(false), 252, null, null, 1, null, _$_1);
  useEffect($$_1(128, "249.250", () => {
    setHasMounted(true);
  }, 253, null, null, 2, null, _$_1), $$_1(1, "249.250", [], 253, null, [], 3, null, _$_1));
  return hasMounted;
}
export default useHasMounted;
registerMetadata_1("249.250", 4, useHasMounted);