'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import { useScrollData } from 'scroll-data-hook';
import { CommandIcon, Moon, Sun } from 'lucide-react';
import { Button } from './ui/button';
import { ColorPalette } from './color-palette';
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import useHasMounted from '@/hooks/useHasMounted';
import useColorTheme from '@/hooks/useColorTheme';
export function CommandMenu() {
  let _$_1 = $$_1(512, "0.1", null, 2, null, null, null, null, null);
  const [open, setOpen] = $$_1(4, "0.1", React.useState(false), 3, null, null, 1, null, _$_1);
  const hasMounter = $$_1(4, "0.1", useHasMounted(), 4, null, null, 2, null, _$_1);
  const {
    theme,
    setColorTheme
  } = $$_1(4, "0.1", useColorTheme(), 5, null, null, 3, null, _$_1);
  const {
    scrolling
  } = $$_1(4, "0.1", useScrollData({
    onScrollStart: () => {
      /* console.log('Started scrolling') */
    },
    onScrollEnd: () => {
      /* console.log('Finished scrolling') */
    }
  }), 6, null, null, 4, null, _$_1);
  React.useEffect($$_1(128, "0.1", () => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(open => !open);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, 7, null, null, 5, null, _$_1), $$_1(1, "0.1", [], 7, null, [], 6, null, _$_1));
  const onToggle = () => {
    setColorTheme(theme === 'dark' ? 'light' : 'dark');
  };
  return <>
      {$$_1(16, "0.1", <Button onClick={onToggle} variant="outline" size="icon" className="fixed bottom-16 right-4 flex rounded-full shadow-2xl sm:bottom-20 print:hidden">
        {hasMounter && theme === 'dark' ? $$_1(16, "0.1", <Sun className="h-4 w-4" />, 9, null, null, 8, 1, _$_1) : $$_1(16, "0.1", <Moon className="h-4 w-4" />, 10, null, null, 9, 1, _$_1)}
      </Button>, 8, null, null, 7, null, _$_1)}
      {$$_1(16, "0.1", <Button onClick={() => setOpen(open => !open)} variant="outline" size="icon" className="fixed bottom-4 right-4 flex rounded-full shadow-2xl sm:bottom-8 print:hidden">
        {$$_1(16, "0.1", <CommandIcon className="h-4 w-4" />, 12, null, null, 11, 1, _$_1)}
      </Button>, 11, null, null, 10, null, _$_1)}
      {!scrolling && $$_1(16, "0.1", <Button onClick={() => void 0} variant="outline" className="fixed bottom-2 left-1/2 -translate-x-1/2 transform rounded-lg p-1.5 text-center text-xs shadow-2xl hidden sm:block print:hidden">
          Press
          {' '}
          <kbd className="border-1 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded bg-muted px-2 text-[10px] font-medium opacity-100">
            <span className="text-xs font-bold text-primary">⌘ J</span>
          </kbd>
          {' '}
          to open the command menu
        </Button>, 13, null, null, 12, null, _$_1)}
      {$$_1(16, "0.1", <CommandDialog open={open} onOpenChange={setOpen}>
        {$$_1(16, "0.1", <CommandInput placeholder="Type a command or search..." />, 17, null, null, 14, 1, _$_1)}
        {$$_1(16, "0.1", <CommandList>
          {$$_1(16, "0.1", <CommandEmpty>No results found.</CommandEmpty>, 19, null, null, 16, null, _$_1)}
          {$$_1(16, "0.1", <CommandGroup heading="Actions">
            {$$_1(16, "0.1", <CommandItem onSelect={() => {
            setOpen(false);
            window.print();
          }}>
              <span>Print</span>
            </CommandItem>, 21, null, null, 18, null, _$_1)}
          </CommandGroup>, 20, null, null, 17, null, _$_1)}

          {$$_1(16, "0.1", <CommandGroup heading="Colors">
            {$$_1(16, "0.1", <ColorPalette />, 24, null, null, 20, 1, _$_1)}
          </CommandGroup>, 23, null, null, 19, null, _$_1)}

          <div className="space-y-2"></div>
        </CommandList>, 18, null, null, 15, null, _$_1)}
      </CommandDialog>, 16, null, null, 13, null, _$_1)}
    </>;
}
registerMetadata_1("0.1", 21, CommandMenu);