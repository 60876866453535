'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import { Label } from '@radix-ui/react-label';
import { uid } from 'uid';
import { getIcon } from '../../icons/getIcon';
import { Badge } from '../ui/badge';
import { Section } from '../ui/section';
import type { IconType, WorksType } from '@/types';
export function Works(props_1) {
  let _$_1 = $$_1(512, "126.127", null, 128, null, null, null, null, null);
  let {
    data
  }: {
    data: WorksType;
  } = $$_1(2, "126.127", props_1, 129, null, null, 0, null, _$_1);
  return $$_1(16, "126.127", <Section>
      {$$_1(16, "126.127", <Label data-testid="work_title" className="text-xl font-bold">
        Work Experience
      </Label>, 131, null, null, 2, null, _$_1)}
      <div data-testid="work_list" className="relative space-y-8 border-l border-gray-200 dark:border-gray-700">
        {data.map((work, index) => <div key={uid(32)} className="relative pl-6">
            {getIcon('dot', {
          className: 'absolute -left-[12px] top-0',
          strokeWidth: 8
        })}
            <div data-testid={`work_details_index_${index}`} className="flex flex-wrap items-center justify-between gap-x-2">
              <div className="inline-flex items-center justify-center gap-x-1 font-semibold leading-none">
                <a className="hover:underline" href={work.link}>
                  {work.company}
                </a>
                <span className="inline-flex gap-x-1">
                  {work.mode.map(badge => $$_1(16, "126.127", <Badge className="align-middle text-[12px]" key={uid(32)}>
                      {badge}
                    </Badge>, 138, null, null, 3, null, _$_1))}
                </span>
              </div>
              <div className="tabular-nums text-gray-500">
                {work.start}
                {' '}
                -
                {' '}
                {work.end ?? 'Present'}
              </div>
            </div>
            <div data-testid={`work_position_index_${index}`} className="text-sm font-semibold">
              {work.position}
            </div>
            <div data-testid={`work_description_index_${index}`} className="mt-2 text-pretty text-gray-700 dark:text-gray-300">
              {work.description}
            </div>
            <div data-testid={`work_skills_index_${index}`} className="mt-2 flex items-baseline">
              <div className="text-sm font-semibold">Skills: </div>
              {work.techStacks && work.techStacks.map(techStack => <div key={uid(32)} className="mx-0.5">
                  {getIcon((techStack as IconType), {
              className: 'size-4'
            })}
                </div>)}
            </div>
          </div>)}
      </div>
    </Section>, 130, null, null, 1, null, _$_1);
}
registerMetadata_1("126.127", 4, Works);