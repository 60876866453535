import(/* webpackMode: "eager", webpackExports: ["CommandMenu"] */ "/home/runner/work/resume/resume/src/components/command-menu.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/resume/resume/src/components/pages/about.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/resume/resume/src/components/pages/education.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/resume/resume/src/components/pages/keySkills.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/resume/resume/src/components/pages/skills.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/resume/resume/src/components/pages/works.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/home/runner/work/resume/resume/src/components/ui/label.tsx");
