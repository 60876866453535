import * as React from 'react';
import type { SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
interface CucumberProps extends SVGProps<SVGSVGElement> {
  className?: string;
}
function Cucumber({
  className
}: CucumberProps) {
  return <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Cucumber" x="0px" y="0px" width="512" height="512" className={twMerge('h-20 w-20', className)}>
      <path fill="#00A818" d="M92.2 8.3c-1-.6-2-1.2-3-1.7l-3.3-1.5c-.4-.1-.7-.3-1.1-.4-1-.4-1.9-.8-2.9-1.1C76.5 1.8 70.8.9 64.8.9 34.3.9 9.7 25.6 9.7 56.1c0 26.9 19.2 49.2 44.5 54.2v15.8c32.9-5 62.1-31.2 64.3-65.6 1.3-20.8-9-42-26.3-52.2zM51.6 21.6c1.8-.2 3.8.5 5.1 2.1 1 1 1.6 2.3 2.3 3.7 2 4.7 1.3 9.8-1.4 13.5-4.7-1-9-4-11-8.7-.7-1.3-1.1-3.1-1.1-4.4.1-3.4 3-5.9 6.1-6.2zM35.1 37.9h1.1c1.7 0 3 .4 4.7 1.1 4.7 2 8.1 6 9.1 10.4-4 2.7-9.5 3.4-14.2 1.4-1.3-.7-2.6-1.4-4-2.4-4.4-3.6-2-10.3 3.3-10.5zm1.1 34.7c-6.1.3-9-6.7-4.6-10.4 1-1 2.3-1.7 4-2.4 1.8-.8 3.6-1.1 5.5-1.2 3.1-.1 6.1.8 8.6 2.5-.7 4.4-4 8.4-8.7 10.4-1.5.8-3.2 1.1-4.8 1.1zm23.2 9.8c-.7 1.3-1.3 2.7-2.3 4-3.4 4.4-11.2 1.3-10.8-4.4 0-1.3.4-3 1.1-4.3 2-4.7 6-7.7 10.7-8.7 2.6 4 3.3 9.1 1.3 13.4zm9.1-55c.7-1.3 1.3-2.7 2.3-4 1.4-1.6 3.4-2.3 5.3-2.1 3.1.3 6.1 2.8 5.9 6.4 0 1.3-.4 3.1-1.1 4.4-2 4.7-6 7.7-10.7 8.7-3-3.6-3.7-8.6-1.7-13.4zm3 59c-1-1-1.6-2.3-2.3-3.7-2-4.7-1.3-9.8 1.4-13.5 4.7 1 9 4 11 8.7.7 1.3 1.1 3.1 1.1 4.4.2 5.2-7.5 8.2-11.2 4.1zm20.6-14.1c-1.7 0-3-.4-4.7-1.1-4.7-2-8.1-6-9.1-10.4 2.5-1.7 5.6-2.6 8.7-2.5 1.8 0 3.7.4 5.5 1.2 1.3.7 2.6 1.3 4 2.3 4.6 3.4 1.6 10.9-4.4 10.5z">
      </path>
    </svg>;
}
export { Cucumber };