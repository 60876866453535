'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';
const labelVariants = cva('text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70');
const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>>(({
  className,
  ...props
}, ref) => {
  let _$_1 = null;
  return $$_1(16, "30.31", <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />, 33, null, null, 1, 1, _$_1);
});
Label.displayName = LabelPrimitive.Root.displayName;
export { Label };
registerMetadata_1("30.31", 2, Label);