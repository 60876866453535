import * as React from 'react';
import type { SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
interface AngularProps extends SVGProps<SVGSVGElement> {
  className?: string;
}
function Angular({
  className
}: AngularProps) {
  return <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Angular" x="0px" y="0px" width="512" height="512" className={twMerge('h-20 w-20', className)}>
      <g id="surface1">
        <path style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: 'rgb(86.666667%,0%,19.215686%)',
        fillOpacity: '1'
      }} d="M 64 15.359375 L 16.332031 32.359375 L 23.601562 95.386719 L 64 117.761719 L 104.398438 95.386719 L 111.667969 32.359375 Z M 64 15.359375 ">
        </path>
        <path style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: 'rgb(76.470588%,0%,18.431373%)',
        fillOpacity: '1'
      }} d="M 64 15.359375 L 64 26.726562 L 64 26.675781 L 64 117.761719 L 104.398438 95.386719 L 111.667969 32.359375 Z M 64 15.359375 ">
        </path>
        <path style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: 'rgb(100%,100%,100%)',
        fillOpacity: '1'
      }} d="M 64 26.675781 L 34.203125 93.492188 L 45.3125 93.492188 L 51.300781 78.539062 L 76.59375 78.539062 L 82.585938 93.492188 L 93.695312 93.492188 Z M 72.703125 69.324219 L 55.296875 69.324219 L 64 48.382812 Z M 72.703125 69.324219 ">
        </path>
      </g>
    </svg>;
}
export { Angular };