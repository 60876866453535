import * as React from 'react';
import type { SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
interface PNPMProps extends SVGProps<SVGSVGElement> {
  className?: string;
}
export function PNPM({
  className
}: PNPMProps) {
  return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" viewBox="76.58987244897958 44 164.00775510204068 164" width="1em" height="1em" className={twMerge('h-20 w-20', className)}>
      <defs>
        <path d="M237.6 95L187.6 95L187.6 45L237.6 45L237.6 95Z" id="arNRoK435" />
        <path d="M182.59 95L132.59 95L132.59 45L182.59 45L182.59 95Z" id="a3H2WU7Px" />
        <path d="M127.59 95L77.59 95L77.59 45L127.59 45L127.59 95Z" id="b1DInM56vl" />
        <path d="M237.6 150L187.6 150L187.6 100L237.6 100L237.6 150Z" id="a7LFlgQIwu" />
        <path d="M182.59 150L132.59 150L132.59 100L182.59 100L182.59 150Z" id="amwLiZcuo" />
        <path d="M182.59 205L132.59 205L132.59 155L182.59 155L182.59 205Z" id="f3Peu5RWan" />
        <path d="M237.6 205L187.6 205L187.6 155L237.6 155L237.6 205Z" id="a6DXBfqPa" />
        <path d="M127.59 205L77.59 205L77.59 155L127.59 155L127.59 205Z" id="c1GWSTH1z7" />
      </defs>
      <g>
        <g>
          <use xlinkHref="#arNRoK435" opacity={1} fill="#f9ad00" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#a3H2WU7Px" opacity={1} fill="#f9ad00" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#b1DInM56vl" opacity={1} fill="#f9ad00" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#a7LFlgQIwu" opacity={1} fill="#f9ad00" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#amwLiZcuo" opacity={1} fill="#4e4e4e" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#f3Peu5RWan" opacity={1} fill="#4e4e4e" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#a6DXBfqPa" opacity={1} fill="#4e4e4e" fillOpacity={1} />
        </g>
        <g>
          <use xlinkHref="#c1GWSTH1z7" opacity={1} fill="#4e4e4e" fillOpacity={1} />
        </g>
      </g>
    </svg>;
}