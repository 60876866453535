import * as React from 'react';
import type { SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
interface WindowsProps extends SVGProps<SVGSVGElement> {
  className?: string;
}
function Windows({
  className
}: WindowsProps) {
  return <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Windows" x="0px" y="0px" width="512" height="512" className={twMerge('h-20 w-20', className)}>
      <path fill="#0078d4" d="M67.328 67.331h60.669V128H67.328zm-67.325 0h60.669V128H.003zM67.328 0h60.669v60.669H67.328zM.003 0h60.669v60.669H.003z">
      </path>
    </svg>;
}
export { Windows };