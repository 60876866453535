'use client';

import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import * as React from 'react';
import type { DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive } from 'cmdk';
import { Search } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Dialog, DialogContent } from '@/components/ui/dialog';
const Command = React.forwardRef<React.ElementRef<typeof CommandPrimitive>, React.ComponentPropsWithoutRef<typeof CommandPrimitive>>(({
  className,
  ...props
}, ref) => {
  let _$_1 = null;
  return $$_1(16, "195.196", <CommandPrimitive ref={ref} className={cn('flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground', className)} {...props} />, 198, null, null, 1, 1, _$_1);
});
Command.displayName = CommandPrimitive.displayName;
interface CommandDialogProps extends DialogProps {}
function CommandDialog(props_1) {
  let _$_2 = $$_1(512, "195.199", null, 200, null, null, null, null, null);
  let {
    children,
    ...props
  }: CommandDialogProps = $$_1(2, "195.199", props_1, 201, null, null, 0, null, _$_2);
  return $$_1(16, "195.199", <Dialog {...props}>
      {$$_1(16, "195.199", <DialogContent className="overflow-hidden p-0 shadow-lg">
        {$$_1(16, "195.199", <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
          {children}
        </Command>, 204, null, null, 3, null, _$_2)}
      </DialogContent>, 203, null, null, 2, null, _$_2)}
    </Dialog>, 202, null, null, 1, null, _$_2);
}
const CommandInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>>(({
  className,
  ...props
}, ref) => {
  let _$_3 = null;
  return <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
    {$$_1(16, "195.205", <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />, 208, null, null, 1, 1, _$_3)}
    {$$_1(16, "195.205", <CommandPrimitive.Input ref={ref} className={cn('flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50', className)} {...props} />, 209, null, null, 2, 1, _$_3)}
  </div>;
});
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandList = React.forwardRef<React.ElementRef<typeof CommandPrimitive.List>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>>(({
  className,
  ...props
}, ref) => {
  let _$_4 = null;
  return $$_1(16, "195.210", <CommandPrimitive.List ref={ref} className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)} {...props} />, 212, null, null, 1, 1, _$_4);
});
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Empty>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>>((props, ref) => {
  let _$_5 = null;
  return $$_1(16, "195.213", <CommandPrimitive.Empty ref={ref} className="py-6 text-center text-sm" {...props} />, 215, null, null, 1, 1, _$_5);
});
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Group>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>>(({
  className,
  ...props
}, ref) => {
  let _$_6 = null;
  return $$_1(16, "195.216", <CommandPrimitive.Group ref={ref} className={cn('overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground', className)} {...props} />, 218, null, null, 1, 1, _$_6);
});
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>>(({
  className,
  ...props
}, ref) => {
  let _$_7 = null;
  return $$_1(16, "195.219", <CommandPrimitive.Separator ref={ref} className={cn('-mx-1 h-px bg-border', className)} {...props} />, 221, null, null, 1, 1, _$_7);
});
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandItem = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Item>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>>(({
  className,
  ...props
}, ref) => {
  let _$_8 = null;
  return $$_1(16, "195.222", <CommandPrimitive.Item ref={ref} className={cn('relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className)} {...props} />, 224, null, null, 1, 1, _$_8);
});
CommandItem.displayName = CommandPrimitive.Item.displayName;
function CommandShortcut(props_2) {
  let _$_9 = $$_1(512, "195.225", null, 226, null, null, null, null, null);
  let {
    className,
    ...props
  }: React.HTMLAttributes<HTMLSpanElement> = $$_1(2, "195.225", props_2, 227, null, null, 0, null, _$_9);
  return <span className={cn('ml-auto text-xs tracking-widest text-muted-foreground', className)} {...props} />;
}
CommandShortcut.displayName = 'CommandShortcut';
export { Command, CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem, CommandShortcut, CommandSeparator };
registerMetadata_1("195.196", 2, Command);
registerMetadata_1("195.199", 4, CommandDialog);
registerMetadata_1("195.205", 3, CommandInput);
registerMetadata_1("195.210", 2, CommandList);
registerMetadata_1("195.213", 2, CommandEmpty);
registerMetadata_1("195.216", 2, CommandGroup);
registerMetadata_1("195.219", 2, CommandSeparator);
registerMetadata_1("195.222", 2, CommandItem);
registerMetadata_1("195.225", 1, CommandShortcut);